//
// user-variables.scss
// Use this to overwrite Bootstrap and Geeks variables
//
// Example of a variable override to change Geeks primary color
// Remove the "//" to comment it in and see it in action!
// $primary: #000;

td {
  vertical-align: middle;
}

td > * {
  vertical-align: middle;
  margin: 0px;
}
#comptitle {
  background-color: #f5f5f5;
  margin-right: calc(-0.5 * var(--bs-gutter-x)) !important;
  margin-left: calc(-0.5 * var(--bs-gutter-x)) !important;
}
.Free,
.New {
  color: #039d00;
}
.Busy,
.Pending,
.Open,
.Accepted,
.Unverified,
.Used {
  color: #dc0000;
}
.Paid,
.Delivered,
.Verified {
  color: #039d00;
}
.Approved,
.Shopped,
.Transit {
  color: #ffb800;
}
.Cancelled {
  color: #4f00b5;
}
.Being {
  color: #0084ae;
}
.row{
  margin-right: 0px !important;
  margin-left: 0px !important;
}
#compsubtitle{
  background-color: #f5f5f5;
  margin-right: 0px !important;
  margin-left: 0px !important;
}